import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRouteMatch, Link, useLocation, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { useDebounce } from "react-use";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import qs from "query-string";
import clsx from "clsx";
import dayjs from "dayjs";
import useToggle from "react-use/lib/useToggle";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";

import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";

import { QUERY_COURIER_DELIVERIES } from "../../../config/graphql/query";
import {
  MUTATION_DELETE_COURIER_DELIVERY,
  MUTATION_RESET_ACTIVE_COURIER_DELIVERY,
} from "../../../config/graphql/mutation";

const limit = 10;

const TableRow = ({
  item,
  variables,
  index,
}: {
  item: ICourierDelivery;
  variables: any;
  index: number;
}) => {
  const {
    id,
    courierService,
    recipientCompany,
    documentNumber,
    signedAt,
    status,
  } = item;

  const [visible, setVisible] = useToggle(false);

  const { t } = useTranslation(["courierDelivery", "common"]);

  const [onDelete, { loading }] = useMutation(
    MUTATION_DELETE_COURIER_DELIVERY,
    {
      refetchQueries: [
        { query: QUERY_COURIER_DELIVERIES, variables },
        { query: QUERY_COURIER_DELIVERIES },
      ],
      variables: { id },
    },
  );

  const onBeforeDelete = () => {
    onDelete()
      .then(() => {
        toast.success<string>(
          t("courierDelivery:courierDeliveries.toast.deleted", { count: 1 }),
        );
        setVisible(false);
      })
      .catch(() => {
        toast.error<string>(
          t("courierDelivery:courierDeliveries.toast.deletedError", {
            count: 1,
          }),
        );
        setVisible(false);
      });
  };

  return (
    <>
      <tr>
        <td className="text-center">{index + 1}</td>
        <td className="h6">{courierService}</td>
        <td>{recipientCompany}</td>
        <td>{documentNumber}</td>
        <td
          className={`bg-${
            // eslint-disable-next-line no-nested-ternary
            status === "Signed"
              ? "success"
              : status === "Active"
              ? "warning"
              : "danger"
          } text-white`}
        >
          {t(
            `courierDelivery:courierDeliveries.td.status.${status.toLowerCase()}`,
          )}
        </td>
        <td>
          {!!signedAt
            ? dayjs(signedAt).local().format("DD-MM-YYYY / HH:mm:ss")
            : "/"}
        </td>
        {/* <td>{dayjs(createdAt).local().format("DD-MM-YYYY / HH:mm:ss")}</td> */}
        <td className="text-nowrap">
          <Button
            size="sm"
            variant="danger"
            className="mr-2"
            onClick={() => setVisible(true)}
          >
            {t("common:delete")}
          </Button>
          <Link to={`/courier-deliveries/${id}`}>
            <Button size="sm" variant="primary">
              {t("common:view")}
            </Button>
          </Link>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("courierDelivery:courierDeliveries.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("courierDelivery:courierDeliveries.modal.body")}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button
            size="sm"
            variant="danger"
            onClick={onBeforeDelete}
            disabled={loading}
          >
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const CourierDeliveries = React.memo(() => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const { t } = useTranslation(["courierDelivery", "common"]);

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
        startDate?: Date;
        endDate?: Date;
      },
    [location.search],
  );

  const [startDate, setStartDate] = useState(
    query.startDate
      ? new Date(dayjs(query.startDate).local().format())
      : undefined,
  );
  const [endDate, setEndDate] = useState(
    query.endDate ? new Date(dayjs(query.endDate).local().format()) : undefined,
  );

  const [dateError, setDateError] = useState(false);

  const [search, setSearch] = useState(query.search);

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const regex = useMemo(() => {
    return {
      REGEX: query.search ? query.search.toString() : "",
      OPTIONS: "i",
    };
  }, [query.search]);

  const variables = useMemo(() => {
    return {
      sort: {
        createdAt: "DESC",
      },
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      ...(query.startDate || query.endDate || query.search
        ? {
            filter: {
              createdAt: {
                ...(query.startDate && { GTE: dayjs(query.startDate) }),
                ...(query.endDate && {
                  LTE: dayjs(query.endDate).add(1, "day"),
                }),
              },
              OR: [
                {
                  courierService: regex,
                },
                {
                  recipientCompany: regex,
                },
                {
                  documentNumber: regex,
                },
              ],
            },
          }
        : {}),
    };
  }, [page, query.startDate, query.endDate, query.search, regex]);

  const { data, startPolling, stopPolling } = useQuery<{
    courierDeliveries?: ICourierDelivery[];
    courierDeliveriesCount: number;
  }>(QUERY_COURIER_DELIVERIES, {
    fetchPolicy: "network-only",
    pollInterval: 0,
    variables,
  });

  const activeCourierDelivery = useMemo(() => {
    if (
      !data ||
      !data.courierDeliveries ||
      data.courierDeliveries.length === 0
    ) {
      return undefined;
    }

    return data.courierDeliveries.find(
      (courierDelivery) => courierDelivery.status === "Active",
    );
  }, [data]);

  const previousActiveCourierDelivery = useRef<ICourierDelivery | undefined>();

  useEffect(() => {
    if (!data || !data.courierDeliveries) {
      return;
    }

    if (
      previousActiveCourierDelivery.current?.status === "Active" &&
      data.courierDeliveries.find(
        (courierDelivery) =>
          courierDelivery.id === previousActiveCourierDelivery.current?.id,
      )?.status === "Signed"
    ) {
      toast.success<string>(t("courierDelivery:courierDelivery.toast.signed"));
    }

    if (!!activeCourierDelivery) {
      startPolling(1000);
    } else {
      stopPolling();
    }

    previousActiveCourierDelivery.current = activeCourierDelivery;
  }, [activeCourierDelivery, startPolling, stopPolling]);

  const courierDeliveries: ICourierDelivery[] = useMemo(
    () => data?.courierDeliveries ?? [],
    [data?.courierDeliveries],
  );
  const courierDeliveriesCount: number = data?.courierDeliveriesCount ?? 0;

  const renderCourierDelivery = useCallback(
    (item: ICourierDelivery, index: number) => (
      <TableRow
        key={item.id}
        item={item}
        variables={variables}
        index={(page - 1) * limit + index}
      />
    ),
    [variables, page],
  );

  useDebounce(
    () => {
      if (
        search !== undefined ||
        startDate !== undefined ||
        endDate !== undefined
      ) {
        history.push({
          search: qs.stringify({
            page: 1,
            ...(search && { search }),
            ...(startDate && { startDate }),
            ...(endDate && { endDate }),
          }),
        });
      }
    },
    500,
    [search, startDate, endDate],
  );

  const [onResetActiveCourierDelivery, { loading }] = useMutation(
    MUTATION_RESET_ACTIVE_COURIER_DELIVERY,
  );

  const onBeforeResetActiveCourierDelivery = () => {
    onResetActiveCourierDelivery()
      .then(() => {
        toast.success<string>(
          t(
            "courierDelivery:courierDeliveries.toast.activeCourierDeliveryReset",
          ),
        );
      })
      .catch(() => {
        toast.error<string>(
          t(
            "courierDelivery:courierDeliveries.toast.activeCourierDeliveryResetError",
          ),
        );
      });
  };

  useEffect(() => {
    setDateError(false);
    if (dayjs(startDate).isAfter(dayjs(endDate))) {
      setDateError(true);
    }
  }, [startDate, endDate]);

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            {t("courierDelivery:courierDeliveries.nav.courierDeliveries")}
          </li>
        </ol>
      </nav>
      <div className="d-flex flex-wrap justify-content-between mt-4">
        <form className="form-inline mr-2">
          <div className="mr-5 mb-4">
            <label htmlFor="search" className="sr-only">
              {t("common:search")}
            </label>
            <input
              id="search"
              type="search"
              className="form-control"
              placeholder={t(
                "courierDelivery:courierDeliveries.placeholder.search",
              )}
              value={search}
              onChange={({ target: { value } }) => {
                setSearch(value);
              }}
            />
          </div>
          <div className="d-flex mb-4">
            <div className="d-flex mr-4">
              <div className="mr-2 d-flex align-items-center text-nowrap">
                {t("courierDelivery:courierDeliveries.date.from")}
              </div>
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => {
                  setStartDate(date);
                  if (date.toDateString() === new Date().toDateString()) {
                    setEndDate(date);
                  }
                }}
                placeholderText="Start Date"
                dateFormat="MM/dd/yyyy"
                className="form-control"
                todayButton="Today"
              />
            </div>
            <div className="d-flex">
              <div className="mr-2 d-flex align-items-center text-nowrap">
                {t("courierDelivery:courierDeliveries.date.to")}
              </div>
              <DatePicker
                selected={endDate}
                onChange={(date: Date) => {
                  setEndDate(date);
                }}
                filterDate={(date) => {
                  if (!!startDate) {
                    return dayjs(date) >= dayjs(startDate);
                  }
                  return true;
                }}
                placeholderText="End Date"
                dateFormat="MM/dd/yyyy"
                className={clsx("form-control", {
                  "is-invalid": dateError,
                })}
              />
            </div>
          </div>
        </form>
        <div>
          {!!activeCourierDelivery && (
            <Button
              className="mb-4 text-white mr-3"
              variant="warning"
              disabled={loading}
              onClick={onBeforeResetActiveCourierDelivery}
            >
              {t(
                "courierDelivery:courierDeliveries.button.resetActiveCourierDelivery",
              )}
            </Button>
          )}
          <Link
            to={`${match.path}/new`}
            type="button"
            className="btn btn-primary mb-4"
          >
            {t("courierDelivery:courierDeliveries.button.add")}
          </Link>
        </div>
      </div>
      {courierDeliveriesCount > 0 && (
        <>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th
                  scope="col"
                  style={{ width: "3.5rem", textAlign: "center" }}
                >
                  #
                </th>
                <th scope="col">
                  {t("courierDelivery:courierDeliveries.th.courierService")}
                </th>
                <th scope="col">
                  {t("courierDelivery:courierDeliveries.th.recipientCompany")}
                </th>
                <th scope="col">
                  {t("courierDelivery:courierDeliveries.th.documentNumber")}
                </th>
                <th scope="col">
                  {t("courierDelivery:courierDeliveries.th.status")}
                </th>
                <th scope="col">
                  {t("courierDelivery:courierDeliveries.th.signedAt")}
                </th>
                {/* <th scope="col">
                  {t("courierDelivery:courierDeliveries.th.createdAt")}
                </th> */}
                <th scope="col">
                  {t("courierDelivery:courierDeliveries.th.actions")}
                </th>
              </tr>
            </thead>
            <tbody>{courierDeliveries.map(renderCourierDelivery)}</tbody>
          </Table>
          <Pagination documentsCount={courierDeliveriesCount} limit={limit} />
        </>
      )}
    </div>
  );
});

export default CourierDeliveries;
